// used in the client-1, hero-21 block

  // '/img/clients/technical-seo-agency/credit-suisse-full.png',
const clientList = [
  // '/img/clients/technical-seo-agency/logos/bbc.png',  
  "/img/clients/technical-seo-agency/logos/bbc.png",
  '/img/clients/technical-seo-agency/logos/itv.png',
  '/img/clients/technical-seo-agency/logos/addidas.png',
  '/img/clients/technical-seo-agency/logos/channel-4.png',
  '/img/clients/technical-seo-agency/logos/christian-aid.svg',
  '/img/clients/technical-seo-agency/logos/credit-suisse.png',
  '/img/clients/technical-seo-agency/logos/frontline.webp',
  '/img/clients/technical-seo-agency/logos/ft.svg',
  '/img/clients/technical-seo-agency/logos/sky.png',
  '/img/clients/technical-seo-agency/logos/technical-seo-agency.svg',
  '/img/clients/technical-seo-agency/logos/thames-water.svg',
  "/img/clients/technical-seo-agency/logos/hogarth.jpg",
]; 
// used in the client-4, client-5 block
const clientList2 = [
  '/img/brands/z1.png',
  '/img/brands/z2.png',
  '/img/brands/z3.png',
  '/img/brands/z4.png',
  '/img/brands/z5.png',
  '/img/brands/z6.png',
  '/img/brands/z7.png',
  '/img/brands/z8.png'
];

export { clientList, clientList2 };
