// used in the portfolio-1 block
export const localSEO = [
  {
    id: 1013,
    mainKeyword: 'local SEO packages',
    subKeyword: 'local SEO services UK',
    category: 'seo, ppc, social, web design',
    title: 'The Brighton Removals Company',
    alt: 'The Brighton Removals Company',
    displayText: 'd-none',
    displayTextColor: 'text-white'
  },
  {
    id: 1022,
    mainKeyword: 'professional UK SEO',
    subKeyword: 'national SEO services',
    category: 'seo, bespoke website design',
    title: 'Gold Bear Removals',
    alt: 'Gold Bear Removals',
    displayText: 'd-none',
    displayTextColor: 'text-dark'
    // excerpt: 'Maintaining number one position in a competitive market for over 10 years.',
    // body: [
    //   'House item packaging is a lucrative and busy market. With long term strategy, any business can stay on top with white hat, consistent SEO focus. '
    // ]
  },
  {
    id: 1018,
    mainKeyword: 'web design for tradesmen',
    subKeyword: 'websites for tradesmen',
    category: 'seo, web design',
    title: 'The Tree Company Sussex',
    alt: 'The Tree Company Sussex',
    displayText: 'd-none',
    displayTextColor: 'text-white'
    // excerpt: 'Maintaining number one position in a competitive market for over 10 years.',
    // body: [
    //   'House item packaging is a lucrative and busy market. With long term strategy, any business can stay on top with white hat, consistent SEO focus. '
    // ]
  },
  {
    id: 1019,
    mainKeyword: 'SEO company',
    subKeyword: 'SEO companies near me',
    category: 'seo, ppc, ecommerce design',
    title: 'Box Boys Packaging',
    alt: 'Box Boys Packaging',
    displayText: 'd-inline-block',
    displayTextColor: 'text-dark'
    // excerpt: 'Maintaining number one position in a competitive market for over 10 years.',
    // body: [
    //   'House item packaging is a lucrative and busy market. With long term strategy, any business can stay on top with white hat, consistent SEO focus. '
    // ]
  },
  {
    id: 1020,
    mainKeyword: 'search engine consultants',
    subKeyword: 'search engine marketing London',
    category: 'seo, web design',
    title: 'Brighton Boxes',
    alt: 'Brighton Boxes',
    displayText: 'd-inline-block',
    displayTextColor: 'text-light'
    // excerpt: 'Maintaining number one position in a competitive market for over 10 years.',
    // body: [
    //   'House item packaging is a lucrative and busy market. With long term strategy, any business can stay on top with white hat, consistent SEO focus. '
    // ]
  },
  {
    id: 1022,
    mainKeyword: 'professional UK SEO',
    subKeyword: 'national SEO services',
    category: 'seo, bespoke website design',
    title: 'Gold Bear Removals',
    alt: 'Gold Bear Removals',
    displayText: 'd-none',
    displayTextColor: 'text-dark'
    // excerpt: 'Maintaining number one position in a competitive market for over 10 years.',
    // body: [
    //   'House item packaging is a lucrative and busy market. With long term strategy, any business can stay on top with white hat, consistent SEO focus. '
    // ]
  },
  {
    id: 1018,
    mainKeyword: 'web design for tradesmen',
    subKeyword: 'websites for tradesmen',
    category: 'seo, web design',
    title: 'The Tree Company Sussex',
    alt: 'The Tree Company Sussex',
    displayText: 'd-none',
    displayTextColor: 'text-white'
    // excerpt: 'Maintaining number one position in a competitive market for over 10 years.',
    // body: [
    //   'House item packaging is a lucrative and busy market. With long term strategy, any business can stay on top with white hat, consistent SEO focus. '
    // ]
  },
  {
    id: 1019,
    mainKeyword: 'SEO company',
    subKeyword: 'SEO companies near me',
    category: 'seo, ppc, ecommerce design',
    title: 'Box Boys Packaging',
    alt: 'Box Boys Packaging',
    displayText: 'd-inline-block',
    displayTextColor: 'text-dark'
    // excerpt: 'Maintaining number one position in a competitive market for over 10 years.',
    // body: [
    //   'House item packaging is a lucrative and busy market. With long term strategy, any business can stay on top with white hat, consistent SEO focus. '
    // ]
  },
  {
    id: 1020,
    mainKeyword: 'search engine consultants',
    subKeyword: 'search engine marketing London',
    category: 'seo, web design',
    title: 'Brighton Boxes',
    alt: 'Brighton Boxes',
    displayText: 'd-inline-block',
    displayTextColor: 'text-light'
    // excerpt: 'Maintaining number one position in a competitive market for over 10 years.',
    // body: [
    //   'House item packaging is a lucrative and busy market. With long term strategy, any business can stay on top with white hat, consistent SEO focus. '
    // ]
  },
  {
    id: 1014,
    mainKeyword: 'affordable SEO London',
    subKeyword: 'affordable SEO services London',
    category: 'seo, ppc',
    title: 'Smooth Mover Brighton',
    alt: 'Smooth Mover Brighton',
    displayText: 'd-inline-block',
    displayTextColor: 'text-dark'
    // excerpt: 'Short term campaign put Smooth Mover at the top of local results.',
    // body: [
    //   'From the designers and engineers who are creating the next generation of web and mobile experiences, to anyone putting a website together for the first time. We provide elegant solutions that set new standards for online publishing.',
    //   'Digital technology has made our world more transparent and interconnected, posing new challenges and opportunities for every business. A holistic, user-centric perspective is what truly sets one apart.'
    // ]
  },
  {
    id: 1015,
    mainKeyword: 'digital marketing for health and wellness',
    subKeyword: 'digital health marketing agency',
    category: 'digital marketing, health',
    title: 'Charlotte Watts',
    alt: 'Charlotte Watts Yoga, Health & Nutrition',
    displayText: 'd-inline-block',
    displayTextColor: 'text-dark'
    // excerpt: 'Maintaining number one position in a competitive market for over 10 years.',
    // body: [
    //   'House item packaging is a lucrative and busy market. With long term strategy, any business can stay on top with white hat, consistent SEO focus. '
    // ]
  },
  {
    id: 1016,
    mainKeyword: 'WordPress SEO agency',
    subKeyword: 'WordPress SEO consultant',
    category: 'seo, ppc, social, web design',
    title: 'London Hair by Jackie',
    alt: 'London Hair by Jackie',
    displayText: 'd-inline-block',
    displayTextColor: 'text-white'
  },
  {
    id: 1017,
    mainKeyword: 'small business marketing consultant',
    subKeyword: 'web design services for small business',
    category: 'seo, ppc, social, woocommerce',
    title: 'The BodySurf School',
    alt: 'The BodySurf School',
    displayText: 'd-inline-block',
    displayTextColor: 'text-white'
    // excerpt: 'Maintaining number one position in a competitive market for over 10 years.',
    // body: [
    //   'House item packaging is a lucrative and busy market. With long term strategy, any business can stay on top with white hat, consistent SEO focus. '
    // ]
  },
  {
    id: 1021, 
    mainKeyword: 'web design West Sussex',
    subKeyword: 'web design Sussex',
    category: 'wordpress, web services',
    title: 'Miguel Madeira Music',
    alt: 'Miguel Madeira Music',
    displayText: 'd-none',
    displayTextColor: 'text-white'
    // excerpt: 'Maintaining number one position in a competitive market for over 10 years.',
    // body: [
    //   'House item packaging is a lucrative and busy market. With long term strategy, any business can stay on top with white hat, consistent SEO focus. '
    // ]
  },
];
// used in the internationalSEO block
export const internationalSEO = [
  {
    id: 0,
    mainKeyword: 'hotel SEO company',
    subKeyword: 'multilingual SEO services',
    category: 'multilingual seo',
    title: 'JJW Hotels',
    alt: 'JJW Hotels',
    displayText: 'd-inline-block',
    displayTextColor: 'text-white'

    // "excerpt": "Provided the technical SEO foundation for multiple websites within the JJW group.",
    // "body": ["Worked in partnership with French content optimsation agency, <a href=\"https://referencement.com/\" target=\"_blank\">R&eacute;f&eacute;rencement.com</a>"]
  },
  {
    id: 1,
    mainKeyword: 'international SEO agency London',
    subKeyword: 'international SEO agency',
    category: 'on-page seo, linking strategy',
    title: 'MBI International',
    alt: 'MBI International',
    displayText: 'd-inline-block',
    displayTextColor: 'text-white'

    // "excerpt": "A quick view of industry specific problems solved with design by the awesome team at Keystroke.",
    // "body": ["From the designers and engineers who are creating the next generation of web and mobile experiences, to anyone putting a website together for the first time. We provide elegant solutions that set new standards for online publishing.", "Digital technology has made our world more transparent and interconnected, posing new challenges and opportunities for every business. A holistic, user-centric perspective is what truly sets one apart."]
  }
];

// used in the portfolio-1 block
export const portfolioList2 = [
  {
    id: 0,
    url: '#',
    category: 'Mobile Design',
    title: 'Cras Fermentum Sem',
    image: { '1x': '/img/photos/sp1.jpg', '2x': '/img/photos/sp1@2x.jpg 2x' }
  },
  {
    id: 1,
    url: '#',
    category: 'Web Design',
    title: 'Venenatis Euismod Vehicula',
    image: { '1x': '/img/photos/sp2.jpg', '2x': '/img/photos/sp2@2x.jpg 2x' }
  },
  {
    id: 2,
    url: '#',
    category: 'Stationary',
    title: 'Tortor Tellus Cursus',
    image: { '1x': '/img/photos/sp3.jpg', '2x': '/img/photos/sp3@2x.jpg 2x' }
  },
  {
    id: 3,
    url: '#',
    category: 'Web Application',
    title: 'Ridiculus Sem Parturient',
    image: { '1x': '/img/photos/sp4.jpg', '2x': '/img/photos/sp4@2x.jpg 2x' }
  },
  {
    id: 4,
    url: '#',
    category: 'Web Design',
    title: 'Cursus Sollicitudin Adipiscing',
    image: { '1x': '/img/photos/sp5.jpg', '2x': '/img/photos/sp5@2x.jpg 2x' }
  },
  {
    id: 5,
    url: '#',
    category: 'Stationary',
    title: 'Fringilla Quam Vulputate',
    image: { '1x': '/img/photos/sp6.jpg', '2x': '/img/photos/sp6@2x.jpg 2x' }
  }
];

// used in the portfolio-4 block
export const portfolioList3 = [
  {
    id: 1,
    photos: 5,
    category: 'Wedding',
    name: 'Lucy & David',
    image: { '1x': '/img/photos/fc1.jpg', '2x': '/img/photos/fc1@2x.jpg 2x' }
  },
  {
    id: 2,
    photos: 8,
    category: 'Wedding',
    name: 'Stacy & Thomas',
    image: { '1x': '/img/photos/fc2.jpg', '2x': '/img/photos/fc2@2x.jpg 2x' }
  },
  {
    id: 3,
    photos: 7,
    category: 'Couples',
    name: 'Katherine & Jack',
    image: { '1x': '/img/photos/fc3.jpg', '2x': '/img/photos/fc3@2x.jpg 2x' }
  },
  {
    id: 4,
    photos: 9,
    category: 'Wedding',
    name: 'Jolene & William',
    image: { '1x': '/img/photos/fc4.jpg', '2x': '/img/photos/fc4@2x.jpg 2x' }
  },
  {
    id: 5,
    photos: 6,
    category: 'Engagement',
    name: 'Jenn & Richard',
    image: { '1x': '/img/photos/fc5.jpg', '2x': '/img/photos/fc5@2x.jpg 2x' }
  },
  {
    id: 6,
    photos: 8,
    category: 'Wedding',
    name: 'Gloria & Leo',
    image: { '1x': '/img/photos/fc6.jpg', '2x': '/img/photos/fc6@2x.jpg 2x' }
  }
];

// used in the portfolio-5 block
export const portfolioList4 = [
  { id: 1, image: 'pf1', title: 'Fringilla Nullam', category: 'drinks events' },
  { id: 2, image: 'pf2', title: 'Ridiculus Parturient', category: 'events' },
  { id: 3, image: 'pf3', title: 'Ornare Ipsum', category: 'pastries events' },
  { id: 4, image: 'pf4', title: 'Nullam Mollis', category: 'events' },
  { id: 5, image: 'pf5', title: 'Euismod Risus', category: 'pastries events' },
  { id: 6, image: 'pf6', title: 'Ridiculus Tristique', category: 'foods' },
  { id: 7, image: 'pf7', title: 'Sollicitudin Pharetra', category: 'foods drinks' },
  { id: 8, image: 'pf8', title: 'Tristique Venenatis', category: 'pastries' },
  { id: 9, image: 'pf9', title: 'Cursus Fusce', category: 'events' },
  { id: 10, image: 'pf10', title: 'Consectetur Malesuada', category: 'foods' },
  { id: 11, image: 'pf11', title: 'Ultricies Aenean', category: 'drinks' },
  { id: 12, image: 'pf12', title: 'Pellentesque Commodo', category: 'foods' },
  { id: 13, image: 'pf13', title: 'Ultricies Aenean', category: 'drinks' }
];

// used in the portfolio-8 block
export const portfolioList5 = [
  { id: 1, color: 'yellow', category: 'Coffee', title: 'Cras Fermentum Sem', image: '/img/photos/pd1.jpg' },
  { id: 2, color: 'red', category: 'Stationary', title: 'Mollis Ipsum Mattis', image: '/img/photos/pd2.jpg' },
  { id: 3, color: 'orange', category: 'Branding', title: 'Ipsum Ultricies Cursus', image: '/img/photos/pd3.jpg' },
  { id: 4, color: 'purple', category: 'Product', title: 'Inceptos Euismod Egestas', image: '/img/photos/pd4.jpg' },
  { id: 5, color: 'leaf', category: 'Print', title: 'Sollicitudin Ornare Porta', image: '/img/photos/pd5.jpg' },
  { id: 6, color: 'aqua', category: 'Workshop', title: 'Ipsum Mollis Vulputate', image: '/img/photos/pd6.jpg' }
];

// used in the portfolio-10, portfolio-11 block
export const portfolioList6 = [
  {
    id: 1,
    category: 'Stationary',
    link: '/single-project-1',
    title: 'Cras Fermentum Sem',
    image: '/img/photos/pd7.jpg',
    fullImage: '/img/photos/pd7-full.jpg'
  },
  {
    id: 2,
    link: '/single-project-1',
    category: 'Magazine, Book',
    title: 'Mollis Ipsum Mattis',
    image: '/img/photos/pd8.jpg',
    fullImage: '/img/photos/pd8-full.jpg'
  },
  {
    id: 3,
    category: 'Packaging',
    link: '/single-project-1',
    title: 'Ipsum Ultricies Cursus',
    image: '/img/photos/pd9.jpg',
    fullImage: '/img/photos/pd9-full.jpg'
  },
  {
    id: 4,
    link: '/single-project-1',
    image: '/img/photos/pd10.jpg',
    category: 'Stationary, Branding',
    title: 'Inceptos Euismod Egestas',
    fullImage: '/img/photos/pd10-full.jpg'
  },
  {
    id: 5,
    category: 'Packaging',
    link: '/single-project-1',
    image: '/img/photos/pd11.jpg',
    title: 'Ipsum Mollis Vulputate',
    fullImage: '/img/photos/pd11-full.jpg'
  }
];

const projects = [
  {
    id: 1,
    category: 'Stationary',
    link: '/single-project-1',
    title: 'Cras Fermentum Sem',
    image: '/img/photos/pd7.jpg',
    fullImage: '/img/photos/pd7-full.jpg'
  },
  {
    id: 2,
    link: '/single-project-1',
    category: 'Magazine, Book',
    title: 'Mollis Ipsum Mattis',
    image: '/img/photos/pd8.jpg',
    fullImage: '/img/photos/pd8-full.jpg'
  },
  {
    id: 3,
    category: 'Packaging',
    link: '/single-project-1',
    title: 'Ipsum Ultricies Cursus',
    image: '/img/photos/pd9.jpg',
    fullImage: '/img/photos/pd9-full.jpg'
  },
  {
    id: 4,
    link: '/single-project-1',
    image: '/img/photos/pd10.jpg',
    category: 'Stationary, Branding',
    title: 'Inceptos Euismod Egestas',
    fullImage: '/img/photos/pd10-full.jpg'
  },
  {
    id: 5,
    category: 'Packaging',
    link: '/single-project-1',
    image: '/img/photos/pd11.jpg',
    title: 'Ipsum Mollis Vulputate',
    fullImage: '/img/photos/pd11-full.jpg'
  }
];
